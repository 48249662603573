<template>
  <span>
    <div :class=" mealRules.newUI? btnClass + ' new_radius' : btnClass" :style="btnStyle" 
         class="main"
         unselectable="on"
         @click="clickBtn"
         onselectstart="return false;">
      <div class="mod" v-if="modLook" :style="mod"></div>
      <slot name="btnContent"></slot>
    </div>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
    export default {
        name: "Btn",
        props: ['btnClass', 'btnStyle'],
        data() {
            return {
                mod: {},
                modLook: false
            }
        },
        computed: {
            ...mapGetters(["mealRules"]),
            
        },
        methods: {
            clickBtn(e) {
                if(e.path){
                    let main = e.path[0];
                    for (let i = 0; i < e.path.length; i++) {
                        if ((e.path[i].className && typeof e.path[i].className == 'string' && (e.path[i].className.indexOf('main') != -1))) {
                            main = e.path[i];
                        }
                    }
                    // console.log(main.offsetHeight)
                    let top = e.clientY - main.offsetTop;
                    if (top > main.offsetHeight) {
                        top = main.offsetHeight / 3;
                    }
                    this.mod = {
                        left: e.clientX - main.offsetLeft + 'px',
                        top: top + 'px',
                        width: main.offsetWidth / 8 + 'px',
                        height: main.offsetWidth / 8 + 'px',
                    }
                    this.modLook = true;
                    // this.$emit('callBack');
                    let _this = this;
                    setTimeout(function () {
                        _this.modLook = false;
                    }, 300)
                }
                this.$emit('callBack');
            }
        }
    }
</script>

<style lang="scss">
    
    .main {
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: var(--themeColor);
        color: #fff;
    }

    .main .mod {
        border-radius: 50%;
        background: var(--clickColor);
        position: absolute;
        animation: bian 0.3s linear both 1;
    }

    @keyframes bian {
        0% {
            opacity: 1;
            transform: scale(1)
        }

        100% {
            opacity: 0;
            transform: scale(18)
        }
    }
</style>
